import React, {useState} from "react";
import Seo from "../components/seo";
import { Layout } from "../components/Layout";
import { GlobalScroll } from "../components/PageHome";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {

  return (
    <Layout>
      <Helmet>
        <meta name="icon" href="/static/favicon.ico" />
      </Helmet>
      <Seo
        title="Home"
        description="Take Flight Intensive"
        //keywords={site.keywords}
      />
      <GlobalScroll />
    </Layout>
  );
};

export default IndexPage;
